import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Dropdown from "./Dropdown";

const Navbar = () => {
  const [showNav, setShowNav] = React.useState(false);
  const [active, setActive] = React.useState('home')
  const handleNav = () => {
    setShowNav((prev) => !prev);
  };

  const handleActive = (page)=>{
   
    setActive(page)
  }
  return (
    <nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link href="/" class="flex items-center">
          <img src="/logo-black.png" class="h-8 mr-3" alt="xSafar Logo" />
        </Link>
        <div class="flex md:order-2">
          <button
            onClick={handleNav}
            data-collapse-toggle="navbar-sticky"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
  className={`items-center justify-between hidden w-full md:flex md:w-auto md:order-1 ${
    showNav ? "" : "hidden"
  }`}
  id="navbar-sticky"
>
          <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <Link
                to="/"
                class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500"
                onClick={()=>handleActive('home')}
                aria-current={active ==='home'?'page' : undefined}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                onClick={()=>handleActive('about')}
                aria-current={active ==='about'?'page' : undefined}
              >
                About
              </Link>
            </li>

            <li>
              <Dropdown />
            </li>

            <li>
              <Link
                to="/project"
                class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                onClick={()=>handleActive('project')}
                aria-current={active ==='project'?'page' : undefined}
              >
                Project
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                onClick={()=>handleActive('contact')}
                aria-current={active ==='contact'?'page' : undefined}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* <div className={` ${showNav ? "md:hidden" : "flex"}`}>
            <p className="md:hidden">test</p>
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
