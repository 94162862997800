import React, { useState } from 'react';

const Dropdown = () => {
  const [selectedOption, setSelectedOption] = useState('Option 1');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div class="flex items-center justify-between p-2 hover:bg-gray-700" ><i class="fas fa-chart-bar mr-2"></i>
      <h3>Informes</h3><i class="fas fa-chevron-down text-xs"></i>
      <select value={selectedOption} onChange={handleChange} class="flex items-center">
        <option value="Option 1">Option 1</option>
        <option value="Option 2">Option 2</option>
        <option value="Option 3">Option 3</option>
      </select>
       
    </div>
  );
};

export default Dropdown;
