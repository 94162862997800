import React from 'react'

export default function About() {
  return (
    <div className="bg-gray-100 min-h-screen">
    <header className="bg-blue-500 p-4">
      <h1 className="text-white text-2xl">This is Services</h1>
    </header>
    <main className="p-4">
      <p className="text-gray-800">This is a Services page to work here.</p>
    </main>

  </div>
  )
}
