import React from "react";
import { Link } from "react-router-dom";

export default function WebsiteDevelopment() {
  return (
    <div>
      {/* hero section */}

      <section class="bg-gradient-to-br border-b-4 border-orange-500 from-red-100 via-green-200 to-indigo-300 dark:bg-gray-900  py-14">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
              Welcome to xSafar <br />{" "}
              <span className="text-orange-500"> Web Development Services</span>
            </h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Expert web development services tailored to elevate your online presence and exceed your digital goals.
            </p>
            <a className="px-6" href="tel:7449703046">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
                {" "}
                Contact Us{" "}
              </button>
            </a>

            <a href="https://wa.me/7449703046">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500">
                {" "}
                Chat with Us{" "}
              </button>
            </a>
          </div>
          <div class="lg:mt-0 lg:col-span-5 lg:flex mt-5">
            <img data-aos="zoom-in-left" src="img/web-service-1.png" alt="mockup" />
          </div>
        </div>
      </section>

      <hr />

      {/* hero section end  */}

      {/* about-it */}

      <section class="bg-gray-200 dark:bg-gray-900 ">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
          
          <img data-aos="fade-right"
            class="w-full dark:hidden "
            src="img/web-service-2.png"
            alt="dashboard image"
          />
           

          <div class="mt-4 md:mt-0  ">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
              We Provide Best Solution
            </h2>
            <p class="mb-6 font-light text-gray-700 md:text-lg dark:text-gray-400">
            At xSafar Technology, we pride ourselves on being a leading provider of cutting-edge web development solutions. Our team of skilled developers and designers is dedicated to crafting exceptional websites that not only look stunning but also deliver seamless user experiences. Whether you are a startup looking to make a splash or an established business aiming to revamp your online presence, we have the expertise to meet your unique requirements.
            </p>
            <Link className="px-6" to="/Contact">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
                {" "}
                Contact Us{" "}
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section class="bg-white dark:bg-gray-900">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
              Why Choose Us?
            </h2>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Experienced Team:</strong>  Our team comprises skilled professionals with a wealth of experience in web development, design, and digital marketing.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Customer-Centric Approach:</strong> We prioritize your needs and goals, working closely with you to deliver the perfect solution.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Creative Excellence: </strong>  We combine innovative design with cutting-edge technologies to create visually stunning and highly functional websites.
            </p>
            
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Timely Delivery:</strong> We understand the importance of meeting deadlines and ensure timely project delivery without compromising on quality.
            </p>

          </div>

          <img data-aos="fade-left"
            class="w-full dark:hidden"
            src="img/web-service-3.png"
            alt="dashboard image"
          />
        </div>
      </section>

      <section class="bg-gray-200 dark:bg-gray-900 ">
        <div class="  flex flex-col-reverse gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img data-aos="fade-right"
            class="w-full dark:hidden"
            src="img/web-service-4.png"
            alt="dashboard image"
          />

          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            Our Services
            </h2>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Custom Web Development:</strong>
Our team specializes in creating tailor-made websites that perfectly align with your business goals and brand identity. We take a collaborative approach, working closely with you to understand your vision and develop a unique website that stands out in the digital landscape.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong>Responsive Design:</strong> In today's mobile-driven world, responsive design is crucial. Our websites are built to adapt flawlessly to various devices, ensuring your users have an optimal experience, regardless of whether they are browsing on a desktop, tablet, or smartphone.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> E-Commerce Solutions: </strong>
Unlock the potential of online retail with our robust e-commerce solutions. From user-friendly product catalogs to secure payment gateways, we create seamless online shopping experiences that maximize conversions and enhance customer satisfaction.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Website Security:</strong> 
Safeguard your website and customer data from cyber threats with our robust security measures. We implement the latest security protocols to provide a safe online environment for your users.
            </p>

            
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong>API Integration:</strong>
Enhance the functionality of your website by seamlessly integrating third-party APIs. From social media integration to payment gateways, we streamline your website's capabilities and user interactions.
            </p>


          </div>
        </div>
      </section>

      {/* about-it end */}

      {/* free consultations */}

      <section class="bg-gradient-to-r from-indigo-500 to-blue-500 dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-sm text-center">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              Request a Free Consultation
            </h2>
            <p class="mb-6 text-gray-100 font-bold md:text-lg">
            Join hands with xSafar Technology and elevate your online presence to new heights. Contact us today to discuss your web development project and turn your ideas into reality!
            </p>
            <Link
              to="/Contact"
              class="text-white bg-orange-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              Contact Now
            </Link>
          </div>
        </div>
      </section>

      {/* end consultation */}
    </div>
  );
}
