import React from "react";
import { Link } from "react-router-dom";

export default function ItConsultations() {
  return (
    <div>
      {/* hero section */}

      <section class="bg-white dark:bg-gray-900 py-14">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
              Welcome to xSafar<br />{" "}
              <span className="text-orange-500"> IT  Consultation Services</span>
            </h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Empowering your Businesses through Expert IT Consultation
            </p>
            <a className="px-6" href="tel:7449703046">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
                {" "}
                Contact Us{" "}
              </button>
            </a>

            <a href="https://wa.me/7449703046">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500">
                {" "}
                Chat with Us{" "}
              </button>
            </a>
          </div>
          <div class="lg:mt-0 lg:col-span-5 lg:flex mt-5">
            <img data-aos="zoom-in-left"
              src="img/it-consult-banner.png"
              alt="mockup"
            />
          </div>
        </div>
      </section>

      <hr />

      {/* hero section end  */}

      {/* about-it */}

      <section class="bg-gray-200 dark:bg-gray-900">
        <div class="gap-8  items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
          <img data-aos="fade-right"
            class="w-full dark:hidden"
            src="img/best-solution.png"
            alt="dashboard image"/>

          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
              We Provide Best Solution
            </h2>
            <p class="mb-6 font-light text-gray-700 md:text-lg dark:text-gray-400">
              At xSafar Technology, IT Consultation, we provide expert advice
              and guidance to businesses of all sizes, helping them leverage
              technology to achieve their goals. Our team of experienced IT
              consultants is committed to understanding your unique challenges
              and delivering tailored solutions that drive growth and
              efficiency.
            </p>
            <Link className="px-6" to="/Contact">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
                {" "}
                Contact Us{" "}
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section class="bg-white dark:bg-gray-900">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
              Why Choose Us?
            </h2>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Experienced Consultants:</strong> Our team consists of
              seasoned IT professionals with years of industry experience and a
              track record of successful consultations.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Comprehensive Expertise: </strong> From IT strategy and
              cybersecurity to cloud solutions and digital transformation, we
              offer a wide range of consulting services to address all your
              technology needs.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Client-Focused Approach: </strong> We believe in putting
              our clients first. Your business objectives and requirements are
              at the heart of our consultation process.
            </p>
          </div>

          <img data-aos="fade-left"
            class="w-full dark:hidden"
            src="img/why-choose-us-service.png"
            alt="dashboard image"
          />
        </div>
      </section>

      <section class="bg-gray-200 dark:bg-gray-900">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
          <img data-aos="fade-right"
            class="w-full dark:hidden"
            src="img/consult-process.png"
            alt="dashboard image"
          />

          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
              Our Consultation Process
            </h2>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Discovery Phase: </strong> We meet with your stakeholders
              to understand your business goals, existing IT infrastructure, and
              pain points.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Analysis and Recommendations: </strong> Based on the
              discovery phase, we conduct a thorough analysis and propose
              tailored recommendations.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Solution Design: </strong> Our team works with you to
              design a comprehensive and scalable solution that meets your
              needs.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Implementation and Support:</strong> We support you
              throughout the implementation process and provide ongoing
              assistance as needed.
            </p>
          </div>
        </div>
      </section>

      {/* about-it end */}

      {/* free consultations */}

      <section class="bg-gradient-to-r from-indigo-500 to-blue-500 dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-sm text-center">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              Request a Free Consultation
            </h2>
            <p class="mb-6 text-gray-100 font-bold md:text-lg">
              Are you ready to elevate your business with expert IT
              consultation? Get in touch with our team today to schedule a free
              consultation. Let's collaborate and make your technology work for
              you.
            </p>
            <Link
              to="/Contact"
              class="text-white bg-orange-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              Contact Now
            </Link>
          </div>
        </div>
      </section>

      {/* end consultation */}
    </div>
  );
}
