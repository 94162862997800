import React from "react";
 

export default function About() {
  return (
    <div>
      <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 mt-20 mb-10">
        {/* header  */}
        <div class="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
          <div class="lg:col-span-3">


          <div data-aos="fade-down-right">

            <h1 class="block text-2xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl dark:text-white">
              Build Better{" "}
              <span className="text-orange-500"> Future with Us</span>
            </h1>
            <p class="mt-3 text-lg text-gray-800 dark:text-gray-400 font-bold ">
              We have an experienced team of production and inspection personnel
              to ensure quality.
            </p>

            </div>

            <div class="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
              <div class="w-full sm:w-auto">
                <label for="hero-input" class="sr-only">
                  Search
                </label>
                <input
                  type="text"
                  id="hero-input"
                  name="hero-input"
                  class="py-3 px-4 block w-full xl:min-w-[18rem] border-gray-200 shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                  placeholder="Enter work email"
                />
              </div>
              <a
                class="w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800"
                href="#"
              >
                Request demo
              </a>
            </div>

            <div class="mt-6 lg:mt-12">
              <span class="text-xs font-medium text-gray-800 uppercase dark:text-gray-200">
                Trusted by:
              </span>

              <div class="mt-4 flex gap-x-8">
                <img
                  className='width="106" height="36"'
                  src="img/grahak-sathi.png"
                />
                
             
                <img
                  className='width="106" height="36"'
                  src="img/brand-6.png"
                />
                <img
                  className='width="106" height="36"'
                  src="img/the-holi-grail.png"
                />
              </div>
            </div>
          </div>

          <div class="lg:col-span-4 mt-10 lg:mt-0">
            <img data-aos="zoom-in-left"
              class="w-full rounded-xl"
              src="https://images.unsplash.com/photo-1665686376173-ada7a0031a85?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&h=700&q=80"
              alt="Image Description"
            />
          </div>
        </div>
      </div>
      {/* header end  */}

      {/* project list  */}

      <div className="container flex px-6 bg-gray-200 justify-center">
        <section>
          {/* <!-- Card Blog --> */}
          <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            {/* <!-- Grid --> */}
            <div class="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
              <h2 class="text-3xl lg:text-4xl text-blue-600 font-bold dark:text-gray-200">
                Our <span className="text-orange-500">Projects</span>
              </h2>

              <p class="mt-3 text-xl font-bold text-gray-800 dark:text-gray-200">
                Here you can explore our Projects
              </p>
            </div>
            <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* <!-- Card --> */}

                         {/* break */}
                         <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg"
                    src="https://xsafartechnology.github.io/timeto-exp.github.io/images/logo.png"
                    alt=""
                  />
                  <a href="https://xsafartechnology.github.io/timeto-exp.github.io/">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://xsafartechnology.github.io/timeto-exp.github.io/" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        Time To Explore{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                     Website Development
                    </p>
                    

                    <div class="text mb-2">
                    Time To Explore is a passionate team of handpicked travel and tourism professionals with rich experience.<br/>
                    Time To Explore experts understand what you are looking for in a trip and spare no effort to make it memorable. 
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >MERN</span>
                    </div>
                  </div> <a href="https://xsafartechnology.github.io/timeto-exp.github.io/" >
                  <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button></a>
                </div>
              </div>

{/* break  */}
              <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg"
                    src="https://xsafartechnology.github.io/the-hidden-path.github.io/img/logo-black.png"
                    alt=""
                  />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://xsafartechnology.github.io/the-hidden-path.github.io/index.html" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        Hidden Path Himalayas{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                      Web Development
                    </p>
                    

                    <div class="text mb-2">
                    Hidden Path Himalayas is a popular company for trekking in the Himalayas. that's to say, our services are distinctive and trustworthy.
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >MERN</span>
                    </div>
                  </div>
                  <a href="https://xsafartechnology.github.io/the-hidden-path.github.io/index.html" >  
                  <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button></a>
                </div>
              </div>

              <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
              
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg"
                    src="https://xsafartechnology.github.io/the-hiking-line.github.io/assets/img/logo.png"
                    alt=""
                  /> 
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://xsafartechnology.github.io/the-hiking-line.github.io/index.html" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        The Hiking Line{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                     Web Development
                    </p>
                    
                    <p class="d-block color-main text-gray-700 m">
                    
                    </p>

                    <div class="text mb-2">
                    The Hiking Line is a full-service travel management company primarily based in India and professionalism, pride, and fun.
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >FrontEnd</span>
                    </div>
                  </div><a href="https://xsafartechnology.github.io/the-hiking-line.github.io/index.html"
                  >
                  <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button>
                  </a> </div>
              </div>

              <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg"
                    src="img/grahak-sathi-img.png"
                    alt=""
                  />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://grahaksathi.in/" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        GrahakSathi{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                      IT Consultation
                    </p>
                 

                    <div class="text mb-2">
                    Grahaksathi is a tech-enabled platform where customers are guided to the right financial product from the right bank or NBFC.
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >MERN</span>
                    </div>
                  </div>
                  <a href="https://grahaksathi.in/" > <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button></a>
                  
                </div>
              </div>

              
            

              <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg "
                    src="img/sugercure.png"
                    alt=""
                  />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://grahaksathi.in/" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        Sugar cure{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                     Web Development
                    </p>
                 
                    <div class="text mb-2">
                    Sugar cure is the First and only Herbal solution for Diabetes in India. Work within 24 hrs of taking.
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >MERN</span>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button>
                </div>
              </div>


   

              <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg"
                    src="img/homo-veda.png"
                    alt=""
                  />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://grahaksathi.in/" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        HOMOEOVEDA{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                     Web Development
                    </p>
                    
                   

                    <div class="text mb-2">
                     HOMOEOVEDA offer AAYUSH (Ayurveda, Yoga, Unani, Siddha, Homeopathy) & Therapies using Indian ethnic medicines.
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >MERN</span>
                    </div>
                  </div> <a href="https://xsafartechnology.github.io/homoeveda.github.io/index.html" >
                  <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button></a>
                </div>
              </div>

              <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg"
                    src="img/sukla-advisor.png"
                    alt=""
                  />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://xsafartechnology.github.io/sukla-advisor.github.io/" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        Sukala Advisor{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                     Website Development
                    </p>
                    

                    <div class="text mb-2">
                    Sukala Advisor is a leading research and advisory firm based in Kolkata with a strong presence among various stockbrokers & merchants.
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >MERN</span>
                    </div>
                  </div> <a href="https://xsafartechnology.github.io/sukla-advisor.github.io/" >
                  <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button></a>
                </div>
              </div>

              
{/* break */}
              <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 ">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"
                    class="rounded-t-lg"
                    src="img/sankhwar-hospital.png"
                    alt=""
                  />
                  <a href="https://xsafartechnology.github.io/shankhwar.github.io/">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-8">
                  <div class="info mb-4">
                    <h5>
                      <a href="https://xsafartechnology.github.io/shankhwar.github.io/" className="hover:text-blue-800 text-2xl font-bold mb-2 ">
                        {" "}
                        DR Sankhwar Hospital{" "}
                      </a>
                    </h5>
                    <p class="d-block color-main text-uppercase text-blue-800  mb-2">
                     Website Development
                    </p>
                    

                    <div class="text mb-2">
                    Provide comprehensive medical treatment and personalized, high-quality patient care through daily assessment and evaluation.
                    </div>
                    <div class="tags">
                       <span className="border-2 black p-1 hover:bg-slate-400" >MERN</span>
                    </div>
                  </div> <a href="https://xsafartechnology.github.io/shankhwar.github.io/" >
                  <button
                    type="button"
                    class="inline-block rounded bg-orange-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:bg-slate-800"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    See Project
                  </button></a>
                </div>
              </div>

              



              

             

             

              {/* <!-- End Card -->

    <!-- Card --> */}
            </div>
          </div>
        </section>
      </div>

      {/* project end  */}
    </div>
  );
}
