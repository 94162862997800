 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
 
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Project from './components/Project';
import Contact from './components/Contact'
import Footer from './components/Footer';
import ItConsultations from './components/ItConsultations';
import DataSecurity from './components/DataSecurity';
import WebsiteDevelopment from './components/WebsiteDevelopment';
import UiUxDesign from './components/UiUxDesign';
import CloudServices from './components/CloudServices';
import GameDevelopment from './components/GameDevelopment';
import CrmAndSoftware from './components/CrmAndSoftware';
import ScrollToTop from './Scroll-to-top';
import SignIn from './components/SignIn';
import RegisterForm from './components/RegisterForm';
import Dashboard from './components/AdminDashboard/Dashboard ';



function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/about" element={  <About />} />
        <Route path="/services" element={  <Services />} />
      < Route path='/project' element={<Project/>}/>
      < Route path='/contact' element={<Contact/>}/> 
      < Route path='/ItConsultations' element={<ItConsultations/>}/> 
      < Route path='/DataSecurity' element={<DataSecurity/>}/> 
      < Route path='/WebsiteDevelopment' element={<WebsiteDevelopment/>}/> 
      < Route path='/UiUxDesign' element={<UiUxDesign/>}/> 
      < Route path='/CloudServices' element={<CloudServices/>}/> 
      < Route path='/GameDevelopment' element={<GameDevelopment/>}/> 
      < Route path='/CrmAndSoftware' element={<CrmAndSoftware/>}/> 
      < Route path='/SignIn' element={<SignIn/>}/> 
      <Route path='/RegisterForm' element={<RegisterForm/>}/>
      <Route path='/Dashboard' element={<Dashboard/>}/>
         
      </Routes>
      <Footer/>
      <ScrollToTop/>
    </Router>
  );
}

export default App;