import React from 'react'
import { Link } from 'react-router-dom'

export default function UiUxDesign() {
  return (
    <div>
    {/* hero section */}

    <section class="bg-gradient-to-br border-b-4 border-orange-500 from-red-100 via-green-200 to-indigo-300 dark:bg-gray-900  py-14">
      <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
          <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
            Welcome to xSafar <br />{" "}
            <span className="text-orange-500"> UI/UX Design Services</span>
          </h1>
          <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
          Transform your vision into captivating experiences with our expert UI/UX design services.
          </p>
          <a className="px-6" href="tel:7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </a>

          <a href="https://wa.me/7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500">
              {" "}
              Chat with Us{" "}
            </button>
          </a>
        </div>
        <div class="lg:mt-0 lg:col-span-5 lg:flex mt-5">
          <img data-aos="zoom-in-left"
            src="img/ux-ui-1.png"
            alt="mockup"
          />
        </div>
      </div>
    </section>

    <hr />

    {/* hero section end  */}

    {/* about-it */}

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/ux-ui-2.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            We Provide Best Solution
          </h2>
          <p class="mb-6 font-light text-gray-700 md:text-lg dark:text-gray-400">
          We believe that exceptional user interface (UI) and user experience (UX) design are crucial elements in creating successful digital products. Our team of talented designers and UX experts are dedicated to crafting visually appealing and intuitive designs that resonate with your target audience and elevate your brand's online presence.
          </p>
          <Link className="px-6" to="/Contact">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </Link>
        </div>
      </div>
    </section>

    <section class="bg-white dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            Why Choose Us?
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Collaborative Approach:</strong>  We believe in close collaboration with our clients to understand their vision and goals, ensuring the final design aligns perfectly with their requirements.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Design Excellence:</strong> Our team is passionate about creating designs that not only look visually appealing but also provide a seamless and enjoyable user experience.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Timely Delivery:</strong>  We value your time and ensure that our design process is efficient, meeting project milestones and deadlines with precision.
          </p>
          
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Client Satisfaction:</strong>  Your satisfaction is our top priority, and we strive to exceed your expectations with every project we undertake.
          </p>

        </div>

        <img data-aos="fade-left"
          class="w-full dark:hidden"
          src="img/ux-ui-3.png"
          alt="dashboard image"
        />
      </div>
    </section>

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/ux-ui-4.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
          Our Services
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>User Experience (UX) Design:</strong>
Our UX design process focuses on creating seamless interactions between users and your digital product. From wire frames and prototyping to user flow optimization, we ensure an intuitive and delightful experience for your users.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> User Interface (UI) Design:</strong> 
We bring aesthetics and functionality together with our UI design services. Our designers carefully craft visually appealing interfaces that align with your brand's identity, enhance usability, and leave a lasting impression on users.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Responsive Design:</strong>
In a mobile-first world, responsive design is paramount. We ensure your digital product looks and functions flawlessly on various devices and screen sizes, providing a consistent experience to all users.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Design System Development:</strong>
For consistent branding and design continuity, we develop comprehensive design systems that serve as a foundation for your digital products. This ensures a cohesive look and feel across all platforms and interactions.
          </p>
        </div>
      </div>
    </section>

    {/* about-it end */}

    {/* free consultations */}

    <section class="bg-gradient-to-r from-indigo-500 to-blue-500 dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
            Request a Free Consultation
          </h2>
          <p class="mb-6 text-gray-100 font-bold md:text-lg">
          Partner with xSafar for UI/UX design services that elevate your digital products and leave a positive and lasting impression on your users. Contact us today to discuss your design needs and embark on a journey of exceptional user experiences!
          </p>
          <Link
            to="/Contact"
            class="text-white bg-orange-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            Contact Now
          </Link>
        </div>
      </div>
    </section>

    {/* end consultation */}
  </div>
  )
}
