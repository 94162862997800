import React from 'react'
import { Link } from 'react-router-dom'

export default function CloudServices() {
  return (
    <div>
    {/* hero section */}

    <section class="bg-gradient-to-br border-b-4 border-orange-500 from-red-100 via-green-200 to-indigo-300 dark:bg-gray-900  py-14">
      <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
          <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
            Welcome to xSafar <br />{" "}
            <span className="text-orange-500"> Cloud Services</span>
          </h1>
          <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
          Unlock the power of the cloud with our cutting-edge cloud services for seamless scalability and efficiency.
          </p>
          <a className="px-6" href="tel:7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </a>

          <a href="https://wa.me/7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500">
              {" "}
              Chat with Us{" "}
            </button>
          </a>
        </div>
        <div class="lg:mt-0 lg:col-span-5 lg:flex mt-5">
          <img data-aos="zoom-in-left"
            src="img/cloud-1.png"
            alt="mockup"
          />
        </div>
      </div>
    </section>

    <hr />

    {/* hero section end  */}

    {/* about-it */}

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/cloud-2.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            We Provide Best Solution
          </h2>
          <p class="mb-6 font-light text-gray-700 md:text-lg dark:text-gray-400">
          At xSafar Technology, we offer a comprehensive range of cloud solutions designed to empower your business and streamline your operations. Whether you are a startup, a small business, or a large enterprise, our cloud services are tailored to meet your specific needs, allowing you to scale, innovate, and stay ahead in today's dynamic digital landscape.
          </p>
          <Link className="px-6" to="/Contact">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </Link>
        </div>
      </div>
    </section>

    <section class="bg-white dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            Why Choose Us?
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Expertise:</strong> Our team consists of certified cloud professionals with vast experience in deploying and managing cloud solutions for diverse industries.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Scalability:</strong> We design our cloud services to be scalable, allowing your business to grow and adapt to changing demands without disruption.
          </p>
          
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Cost Efficiency:</strong>  Our cloud solutions are optimized to provide cost-effective services, enabling you to pay only for the resources you need, when you need them.
          </p>

          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Reliability:</strong> We prioritize reliability and uptime, ensuring your applications and services are available and perform optimally at all times.
          </p>

          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Customer Support:</strong>  Our dedicated support team is available to assist you with any queries or issues, providing timely and efficient resolution.
          </p>
        </div>

        <img data-aos="fade-left"
          class="w-full dark:hidden"
          src="img/cloud-3.png"
          alt="dashboard image"
        />
      </div>
    </section>

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/cloud-4.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
          Our Cloud Services Offerings
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Cloud Consultation and Strategy:
</strong> Our experienced cloud consultants work closely with you to understand your business objectives and IT infrastructure. We create a tailored cloud strategy that aligns with your goals, helping you choose the right cloud platform and services for your unique requirements.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Cloud Migration:</strong>
Migrating to the cloud can be a complex process, but our experts ensure a smooth transition of your applications, data, and workloads to the cloud. We minimize downtime, mitigate risks, and optimize performance during the migration process.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Platform as a Service (PaaS):</strong>
Streamline your application development and deployment with our PaaS solutions. Our platform services offer a ready-to-use environment, allowing your developers to focus on building innovative applications without worrying about managing the underlying infrastructure.
          </p>

          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Software as a Service (SaaS):</strong>
Access a wide range of software applications over the internet without the need for installation or maintenance. Our SaaS offerings provide you with user-friendly and cost-effective solutions for various business needs.
          </p>
          

          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Cloud Security Solutions:</strong>
Safeguard your data and applications in the cloud with our robust security measures. We implement industry-leading security protocols, identity and access management, encryption, and monitoring to protect your valuable assets from cyber threats.
          </p>

          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Cloud Backup and Disaster Recovery:</strong>
Ensure business continuity with our reliable cloud backup and disaster recovery solutions. We provide automated backups, replication, and recovery options, ensuring your data is protected and accessible even in the face of unexpected events.
          </p>

        </div>
      </div>
    </section>

    {/* about-it end */}

    {/* free consultations */}

    <section class="bg-gradient-to-r from-indigo-500 to-blue-500 dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
            Request a Free Consultation
          </h2>
          <p class="mb-6 text-gray-100 font-bold md:text-lg">
          Embrace the power of the cloud with <strong> xSafar Technology </strong>  . Contact us today to explore how our cloud services can transform your business and drive innovation. Let's embark on a journey to a more agile, scalable, and secure future together!
          </p>
          <Link
            to="/Contact"
            class="text-white bg-orange-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            Contact Now
          </Link>
        </div>
      </div>
    </section>

    {/* end consultation */}
  </div>
  )
}
