import React, { useState } from "react";
import { Link } from "react-router-dom";
import ItConsultations from "./ItConsultations";

const Dropdown = () => {
  const [open, setOpen] = useState(false);

  const handleDropdownToggle = () => {
    setOpen(true);
  };

  return (
    <div onMouseLeave={() => setOpen(false)} className="relative">
    <button onClick={handleDropdownToggle}
        onMouseOver={handleDropdownToggle}
       
          className="flex items-center whitespace-nowrap rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 py-2 pl-3 pr-4 font-medium leading-normal text-gray-900 transition hover:duration-150 ease-in-out motion-reduce:transition-none"
        >
          <span className="mr-2">Services</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg> </button>
    <li>
      <div className="relative" data-te-dropdown-ref>
         
        <ul 
          className={`absolute right-0 w-40 py-2   rounded-lg shadow-xl ${
            open ? "block" : "hidden "
          }`}>
          <li>
            <div onClick={<ItConsultations />}>
              <Link
                className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-bold text-black bg-white hover:bg-orange-500  hover:text-white active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600 "
                to="/ItConsultations"
                data-te-dropdown-item-ref
              >
                IT Consultations
              </Link>
            </div>
          </li>

          <li>
            <Link
              className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-bold text-black bg-white hover:bg-orange-500 hover:text-white active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
              to="/DataSecurity"
              data-te-dropdown-item-ref
            >
              Data Security
            </Link>
          </li>

          <li>
            <Link
              className="block w-full whitespace-nowrap bg-transparent px-2 py-2 text-sm font-bold text-black bg-white hover:bg-orange-500 hover:text-white active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600 "
              to="/WebsiteDevelopment"
              data-te-dropdown-item-ref
            >
              Website Development
            </Link>
          </li>

          <li>
            <Link
              className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-bold text-black bg-white hover:bg-orange-500 hover:text-white active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
              to="/UiUxDesign"
              data-te-dropdown-item-ref
            >
              UI/UX Design
            </Link>
          </li>

          <li>
            <Link
              className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-bold text-black bg-white hover:bg-orange-500 hover:text-white active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
              to="/CloudServices"
              data-te-dropdown-item-ref
            >
              Cloud Services
            </Link>
          </li>

          <li>
            <Link
              className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-bold text-black bg-white hover:bg-orange-500 hover:text-white active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
              to="/GameDevelopment"
              data-te-dropdown-item-ref
            >
              Game Development
            </Link>
          </li>

          <li>
            <Link
              className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-bold text-black bg-white hover:bg-orange-500 hover:text-white active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
              to="/CrmAndSoftware"
              data-te-dropdown-item-ref
            >
              CRM & Software
            </Link>
          </li>
        </ul>
      </div>
    </li>
    </div>
  );
};

export default Dropdown;
