import { React, useState } from "react";
import "./Home.css";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Home() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:4000/send-email",
        formData
      );

      console.log("Email sent successfully", response);
      setFormData({
        name: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Fail to send email", error);
    }
  };

  return (
    <div className="container">
      <header className="w-full h-auto py-7 px-3 mt-16 md:flex container-fluid bg-gradient-to-br from-red-100 via-green-200 to-indigo-300 flex justify-center">
        <section class=" dark:bg-gray-900">
          <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7 ">
              <div className="py-8 px-7" data-aos="zoom-in">
                <p className="text-l text-teal-600 font-mono">
                  {" "}
                  XSAFAR TECHNOLOGY{" "}
                </p>
                <h2 className="mt-2 font-bold text-5xl mb-6">
                  {" "}
                  Technology &amp; IT <br />{" "}
                  <span className="text-blue-500 py-2">Solutions</span>
                </h2>
                <h5 className="text-teal-700 text-bold mb-5">
                  We transform businesses of most major sectors with powerful
                  and adaptable digital solutions that satisfy the needs of
                  today.
                </h5>
              </div>

              <button
                type="button"
                class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 ml-7 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                <Link to="/services">Our Services</Link>
              </button>
              <button
                type="button"
                class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                <Link to="/contact">Contact Us</Link>
              </button>
            </div>

            <div
              data-aos="fade-left"
              class="mt-7 lg:mt-0 lg:col-span-5 lg:flex"
            >
              <img src="/img/home-hero.png" alt="mockup" />
            </div>
          </div>
        </section>
      </header>

      <div className="container">
        <section class=" text-gray-600 body-font overflow-hidden bg-image">
          <div class="container px-5 py-24 mx-auto ">
            <div class="lg:w-4/5 mx-auto flex flex-wrap  content-div">
              <img
                alt=""
                data-aos="fade-right"
                data-aos-duration="2000"
                class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded px-4 py-4"
                src="img/content-img.png"
              />
              <div
                data-aos="fade-left"
                data-aos-duration="2000"
                class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-1 px-4 py-4"
              >
                <h2 class="text-sm title-font text-gray-200 tracking-widest mb-2">
                  xSafar Technology
                </h2>
                <h1 class="text-gray-900 text-3xl title-font font-medium mb-1 text-white py-3">
                  “Technology is best when it brings people together.”
                </h1>
                <h6 className="text-white mb-3">PATRICIA CROSS</h6>
                {/* <div class="flex mb-4">
          <span class="flex items-center">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-indigo-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
           
            
            <span class="text-gray-600 ml-3">4 Reviews</span>
          </span>
          <span class="flex ml-3 pl-3 py-2 border-l-2 border-gray-200 space-x-2s">
            <a class="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a class="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a class="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
              </svg>
            </a>
          </span>
        </div> */}
                <p class="leading-relaxed text-white px-3 py-2">
                  We can help to maintain and modernize your IT infrastructure &
                  solve various infrastructure-specific issues a business may
                  face.
                </p>

                <p class="leading-relaxed text-white px-3 py-2">
                  Iteck Co is the partner of choice for many of the world's
                  leading enterprises, SMEs and technology challengers. We help
                  businesses elevate their value through custom software
                  development, product design, QA and consultancy services.
                </p>

                <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5">
                  <div class="flex">
                    <span class="mr-3">Color</span>
                    <button class="border-2 border-gray-300 rounded-full w-6 h-6 focus:outline-none"></button>
                    <button class="border-2 border-gray-300 ml-1 bg-gray-700 rounded-full w-6 h-6 focus:outline-none"></button>
                    <button class="border-2 border-gray-300 ml-1 bg-indigo-500 rounded-full w-6 h-6 focus:outline-none"></button>
                  </div>
                  {/* <div class="flex ml-6 items-center">
            <span class="mr-3">Size</span>
            <div class="relative"> 
              <select class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus: focus:border-indigo-500 text-base pl-3 pr-10">
             
                <option>L</option>
                <option>XL</option>
              </select>
              <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </div>
          </div> */}
                </div>
                <div class="flex">
                  {/* <span class="title-font font-medium text-2xl text-gray-900">$58.00</span> */}
                  <Link to="/about" className=" ml-auto">
                    <button class="flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
                      More About Us
                    </button>
                  </Link>
                  <button class="rounded-full w-10 h-10 bg-gray-200 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-4">
                    <svg
                      fill="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <section class="container text-gray-600 body-font py-10">
                <div class="container px-5 mx-100%">
                  <div class="flex flex-wrap justify-center -m-4 text-center">
                    <div class="p-4 sm:w-1/2 w-full md:w-1/4">
                      <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-200">
                        10+
                      </h2>
                      <p class="leading-relaxed text-yellow-50">
                        Years of Experience
                      </p>
                    </div>
                    <div class="p-4 sm:w-1/2 w-full md:w-1/4">
                      <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-200">
                        1.2K+
                      </h2>
                      <p class="leading-relaxed text-yellow-50">
                        Projects completed
                      </p>
                    </div>
                    <div class="p-4 sm:w-1/2 w-full md:w-1/4">
                      <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-200">
                        200+
                      </h2>
                      <p class="leading-relaxed text-yellow-50">
                        Awards Achieved
                      </p>
                    </div>
                    <div class="p-4 sm:w-1/2 w-full md:w-1/4">
                      <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-200">
                        200+
                      </h2>
                      <p class="leading-relaxed text-yellow-50">
                        Satisfied clients on 6 countries
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        {/* card section  */}

        <div class=" card-sec px-3 md:lg:xl:px-40  border-t border-b py-20 bg-opacity-10">
          <div class="flex flex-col justify-start pl-9 mb-10">
            <div class="max-w-sm font-bold font-sans text-blue-600">
              OUR SERVICES
            </div>
            <div class="font-bold max-w-lg mt-5 text-3xl text-gray-600 ">
              Perfect IT Solutions{" "}
              <span className="font-light">For Your Business.</span>
            </div>
          </div>

          <div class="grid grid-cols-1 md:lg:xl:grid-cols-3 group bg-white shadow-xl shadow-neutral-100 border ">
            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200">
                <Link to="/ItConsultations">
                  {" "}
                  <img
                    className="w-12 hover:opacity-30"
                    data-aos="flip-right"
                    src="img/it-support.png"
                  />
                </Link>
              </span>
              <Link to="/ItConsultations">
                {" "}
                <p class="text-xl font-medium text-slate-700 mt-3 hover:text-orange-500">
                  IT Consultation
                </p>{" "}
              </Link>
              <p class="mt-2 text-sm text-slate-500">
                {" "}
                we provide expert IT consultation services to businesses of all
                sizes. Our team of experienced IT consultants is dedicated to
                helping you make informed decisions about your technology
                infrastructure, strategy, and operations.{" "}
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-orange-500 text-white shadow-lg shadow-orange-200">
                <Link to="/DataSecure">
                  {" "}
                  <img
                    className="w-12 hover:opacity-30"
                    data-aos="flip-right"
                    src="img/secure-data.png"
                  />
                </Link>
              </span>
              <Link to="/DataSecure">
                {" "}
                <p class="text-xl font-medium text-slate-700 mt-3 hover:text-orange-500">
                  Data Security
                </p>{" "}
              </Link>
              <p class="mt-2 text-sm text-slate-500">
                At xSafar Technology, we take data security seriously. We
                understand the importance of protecting your personal
                information and maintaining your trust. This page outlines the
                measures we have implemented to ensure the security of your data
                while using our platform.
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group   md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-yellow-500 text-white shadow-lg shadow-yellow-200">
                <Link to="/WebDevelopment">
                  {" "}
                  <img
                    className="w-12 hover:opacity-30"
                    data-aos="flip-right"
                    src="img/developer.png"
                  />
                </Link>
              </span>
              <Link to="/WebDevelopment">
                {" "}
                <p class="text-xl font-medium text-slate-700 mt-3 hover:text-orange-500">
                  Website Development
                </p>
              </Link>
              <p class="mt-2 text-sm text-slate-500">
                we specialize in delivering high-quality and custom website
                development solutions that help businesses thrive in the digital
                landscape. With a team of skilled developers and designers, we
                bring your ideas to life, creating stunning, user-friendly, and
                functional websites that leave a lasting impression on your
                audience.
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group   md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-lime-500 text-white shadow-lg shadow-lime-200">
                <Link to="/UiUxDesign">
                  {" "}
                  <img
                    className="w-12 hover:opacity-30"
                    data-aos="flip-right"
                    src="img/design.png"
                  />
                </Link>
              </span>
              <Link to="/UiUxDesign">
                {" "}
                <p class="text-xl font-medium text-slate-700 mt-3 hover:text-orange-500">
                  UI/UX Design
                </p>
              </Link>
              <p class="mt-2 text-sm text-slate-500">
                Our UI/UX design services focus on creating intuitive and
                visually appealing user interfaces that elevate the overall user
                experience. With a team of skilled designers, we are committed
                to delivering designs that captivate your audience and drive
                meaningful interactions.
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group    md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-teal-500 text-white shadow-lg shadow-teal-200">
                {" "}
                <Link to="/CloudServices">
                  {" "}
                  <img
                    className="w-12 hover:opacity-30"
                    data-aos="flip-right"
                    src="img/cloud-service.png"
                  />
                </Link>
              </span>
              <Link to="/CloudServices">
                {" "}
                <p class="text-xl font-medium text-slate-700 mt-3 hover:text-orange-500">
                  Cloud Services
                </p>
              </Link>
              <p class="mt-2 text-sm text-slate-500">
                {" "}
                We offer a comprehensive range of cloud solutions that enable
                businesses to harness the full potential of cloud computing.
                Embrace the agility, scalability, and cost-efficiency of the
                cloud as we tailor our services to meet your unique business
                requirements.
              </p>
            </div>

            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
              <span class="p-5 rounded-full bg-indigo-500 text-white shadow-lg shadow-indigo-200">
                <Link to="/GameDevelopment">
                  {" "}
                  <img
                    className="w-12 hover:opacity-30"
                    data-aos="flip-right"
                    src="img/game-development.png"
                  />
                </Link>
              </span>
              <Link to="/GameDevelopment">
                {" "}
                <p class="text-xl font-medium text-slate-700 mt-3 hover:text-orange-500">
                  Game Development
                </p>
              </Link>
              <p class="mt-2 text-sm text-slate-500">
                We create games for Mobile, NFT, Metaverse, PC and Web
                platforms. Our developers have extensive experience with
                state-of-the-art game engines such as Unity and Unreal, as well
                as hands-on experience in various genres.
              </p>
            </div>
          </div>

          <div class="w-full mt-5   bg-indigo-600 shadow-xl shadow-indigo-200 py-10 px-20 flex flex-wrap justify-between items-center">
            <p class=" text-white">
              {" "}
              <span class="text-4xl font-medium">
                Still Confused ?
              </span> <br />{" "}
              <span class="text-lg">
                Book For Free Career Consultation Today !{" "}
              </span>
            </p>

            <a href="https://wa.me/7449703046" className="mt-6 ">
              <button class="px-5 py-3  font-medium text-slate-100 shadow-xl rounded-md hover:text-orange-500 hover:bg-white duration-150  bg-orange-500">
                {" "}
                BOOK AN APPOINTMENT{" "}
              </button>
            </a>
          </div>
        </div>

        {/* card end  */}

        {/* choose us section  */}
      </div>

      <div className="container ">
        <div class=" container-fluid flex flex-wrap bg-gradient-to-r from-red-300 via-indigo-400 to-blue-500 ">
          {/* <!-- Content Block --> */}
          <div class="w-full lg:w-1/2 p-4 py-10 px-12">
            <h2 class="text-xl font-bold py-4 text-blue-500 font-mono">
              WHY CHOOSE US
            </h2>
            <h2 class="text-3xl font-bold py-2 mt-3 text-gray-700">
              Boost Your Business
            </h2>
            <h2 class="text-3xl mb-8 text-gray-700">With New Tech</h2>
            <p class="text-lg mb-4 text-gray-800">
              {" "}
              Our team can assist you in transforming your business through
              latest tech capabilities to stay ahead of the curve.
            </p>

            <div className="flex">
              <img
                className="w-5 h-5 mt-1"
                data-aos="fade-right"
                src="img/circle.png"
              />
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white ml-3 mb-2">
                Latest IT Solutions & Integration With Blockchain
              </h2>
            </div>
            <div className="flex">
              <img
                className="w-5 h-5 mt-1"
                data-aos="fade-right"
                src="img/circle.png"
              />
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white ml-3 mb-2">
                {" "}
                Over 100+ Payment Gateways Support
              </h2>
            </div>
            <div className="flex">
              <img
                className="w-5 h-5 mt-1"
                data-aos="fade-right"
                src="img/circle.png"
              />
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white ml-3 mb-2">
                {" "}
                AI Machine & Deep Learning
              </h2>
            </div>
            <div className="flex mb-8">
              <img
                className="w-5 h-5 mt-1"
                data-aos="fade-right"
                src="img/circle.png"
              />
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white ml-3 mb-2">
                {" "}
                Dedicated Support 24/7
              </h2>
            </div>
            <div className="ml-5">
              <Link to="/project">
                <button class="rounded-full px-3 py-3 bg-gradient-to-r from-sky-700 to-indigo-900 text-gray-300 font-bold hover:bg-gradient-to-r from-indigo-900 to-sky-700  text-gray-300">
                  See our Projects
                </button>{" "}
              </Link>
            </div>
          </div>

          {/* <!-- Image Block --> */}
          <div class="w-full lg:w-1/2 p-4">
            <img
              src="img/why-choose-us.png"
              data-aos="zoom-in"
              data-aos-duration="1500"
              alt="Image description"
              class="w-full h-auto"
            />
          </div>
        </div>
      </div>

      {/* choose us section end  */}

      {/* contact section  */}

      <div className="container mt-5 mb-5">
        <div className="container mx-auto pt-16">
          <div className="lg:flex">
            <div className="xl:w-3/5 lg:w-3/5 bg-indigo-700 py-16 xl:rounded-bl rounded-tl rounded-tr xl:rounded-tr-none">
              <div className="xl:w-5/6 xl:px-0 px-8 mx-auto">
                <h1 className="xl:text-4xl text-3xl pb-4 text-white font-bold">
                  Get in touch
                </h1>
                <p className="text-xl text-white pb-8 leading-relaxed font-normal lg:pr-4">
                  Got a question about us? Are you interested in partnering with
                  us? Have some suggestions or just want to say Hi? Just contact
                  us. We are here to asset you.
                </p>
                <div className="flex pb-4 items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-phone-call"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#ffffff"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1" />
                      <path d="M15 7a2 2 0 0 1 2 2" />
                      <path d="M15 3a6 6 0 0 1 6 6" />
                    </svg>
                  </div>
                  <p className="pl-4 text-white text-base">+91-7449-703-046</p>
                </div>
                <div className="flex items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-mail"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#FFFFFF"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <rect x={3} y={5} width={18} height={14} rx={2} />
                      <polyline points="3 7 12 13 21 7" />
                    </svg>
                  </div>
                  <p className="pl-4 text-white text-base">info@xsafar.com</p>
                </div>
                <p className="text-lg text-white pt-10 tracking-wide">
                  xSafar Technology, DN Block,Salt Lake Sec-V,
                  <br /> Kolkata, West Bengal-700091
                </p>
              </div>
            </div>
            <div className="xl:w-3/5 lg:w-3/5 bg-gray-200 h-full   xl:  xl:pl-0 rounded-tr rounded-br">
              <form action="#" class="space-y-8 py-4 px-6 " onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="name"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Type Your Name here..."
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Type your Email here"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="text"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Your Mobile Number
                  </label>
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Type your Mobile Number here"
                    maxLength={10}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="subject"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Let us know how we can help you"
                    required
                  />
                </div>
                <div class="sm:col-span-2">
                  <label
                    htmlFor="message"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Your message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="6"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Leave a comment..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="py-3 bg-orange-500 px-5 text-sm font-medium text-center text-white rounded-lg  sm:w-fit   focus:ring-4 focus:outline-none focus:ring-primary-300   hover:bg-orange-600 hover:tracking-wide"
                >
                  Send message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* contact section end  */}
    </div>
  );
}
