import React from 'react'
import { Link } from 'react-router-dom'

export default function DataSecurity() {
  return (
    <div>
      {/* hero section */}

      <section class="bg-gradient-to-br border-b-4 border-orange-500 from-red-100 via-green-200 to-indigo-300 dark:bg-gray-900  py-14">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 ">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
              Welcome to xSafar <br />{" "}
              <span className="text-orange-500"> Data Security Services</span>
            </h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Data security is not a one-time task; it's a constant commitment to protect what matters most
            </p>
            <a className="px-6" href="tel:7449703046">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
                {" "}
                Contact Us{" "}
              </button>
            </a>

            <a href="https://wa.me/7449703046">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500">
                {" "}
                Chat with Us{" "}
              </button>
            </a>
          </div>
          <div class="lg:mt-0 lg:col-span-5 lg:flex mt-5 ">
            <img data-aos="zoom-in-left"
              src="img/it-consult-banner.png"
              alt="mockup"
            />
          </div>
        </div>
      </section>

      <hr />

      {/* hero section end  */}

      {/* about-it */}

       
  {/* <!-- Grid --> */}
  <div class="mx-auto max-w-2xl mb-8 lg:mb-14 text-center mt-10">
    <h2 class="text-3xl lg:text-4xl text-gray-800 font-bold dark:text-gray-200 ">
    Introduction
    </h2>
    <p class="mt-3 text-gray-800 dark:text-gray-200 ">
    At xSafar Technology, we understand the critical importance of data security in today's digital landscape. With cyber threats on the rise, safeguarding your sensitive information is paramount. Our comprehensive data security solutions are designed to provide businesses with robust protection, peace of mind, and the confidence to focus on what matters most—growth and success.
    </p><div class="border-b-4 border-blue-400 mt-12"></div>
  </div>
  
  <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6"></div>

 

      <section class="bg-gray-200 dark:bg-gray-900">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
          <img data-aos="fade-right"
            class="w-full dark:hidden"
            src="img/commitment-data-secure.png"
            alt="dashboard image"
          />

          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            Our Commitment to Data Security
            </h2>
            <p class="mb-3 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Industry Expertise:</strong> Our team of experienced security professionals stays up-to-date with the latest threats and best practices to deliver cutting-edge security solutions.
            </p>

            
            <p class="mb-3 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Tailored Approach:</strong> We assess your unique security needs and design personalized strategies to address potential vulnerabilities effectively.
            </p>

            <p class="mb-3 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Proactive Monitoring:</strong> Our proactive approach to monitoring and threat detection ensures timely identification and response to potential security breaches.</p>

            <p class="mb-3 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Data Privacy Compliance:</strong> We adhere to industry regulations and data privacy standards, ensuring your data remains secure and compliant.</p>

           

             
            <Link className="px-6" to="/Contact">
              <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
                {" "}
                Contact Us{" "}
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section class="bg-white dark:bg-gray-900">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
              Why Choose Us?
            </h2>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Experience and Expertise:</strong> With years of experience in data security, our team has the expertise to protect your data from evolving threats.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Holistic Solutions: </strong> We take a comprehensive approach to data security, addressing all aspects of your digital environment.
            </p>

            
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Client-Centric Focus: </strong> Our solutions are tailored to your specific needs and budget, ensuring maximum value for your investment.
            </p>

            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> 24/7 Support: </strong>Our dedicated support team is available round-the-clock to assist with any security-related concerns.
            </p>

 
          </div>

          <img data-aos="fade-left"
            class="w-full dark:hidden"
            src="img/why-choose-us-service.png"
            alt="dashboard image"
          />
        </div>
      </section>

      <section class="bg-gray-200 dark:bg-gray-900">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
          <img data-aos="fade-right"
            class="w-full dark:hidden"
            src="img/data-consult-secure.png"
            alt="dashboard image"
          />

          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
              Our Consultation Process
            </h2>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Discovery Phase: </strong> We meet with your stakeholders
              to understand your business goals, existing IT infrastructure, and
              pain points.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Analysis and Recommendations: </strong> Based on the
              discovery phase, we conduct a thorough analysis and propose
              tailored recommendations.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Solution Design: </strong> Our team works with you to
              design a comprehensive and scalable solution that meets your
              needs.
            </p>
            <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
              <strong> Implementation and Support:</strong> We support you
              throughout the implementation process and provide ongoing
              assistance as needed.
            </p>
          </div>
        </div>
      </section>

      {/* about-it end */}

      {/* free consultations */}

      <section class="bg-gradient-to-r from-indigo-500 to-blue-500 dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-sm text-center">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              Request a Free Consultation
            </h2>
            <p class="mb-6 text-gray-100 font-bold md:text-lg">
              Are you ready to elevate your business with expert IT
              consultation? Get in touch with our team today to schedule a free
              consultation. Let's collaborate and make your technology work for
              you.
            </p>
            <Link
              to="/Contact"
              class="text-white bg-orange-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              Contact Now
            </Link>
          </div>
        </div>
      </section>

      {/* end consultation */}
    </div>
  )
}
