import React from 'react'
import { Link } from 'react-router-dom'

export default function CrmAndSoftware() {
  return (
    <div>
    {/* hero section */}

    <section class="bg-gradient-to-br border-b-4 border-orange-500 from-red-100 via-green-200 to-indigo-300 dark:bg-gray-900  py-14">
      <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
          <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
            Welcome to xSafar <br />{" "}
            <span className="text-orange-500"> CRM And Software Services</span>
          </h1>
          <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
          Streamline your business operations with our advanced CRM and software services for enhanced efficiency and growth.
          </p>
          <a className="px-6" href="tel:7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </a>

          <a href="https://wa.me/7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500">
              {" "}
              Chat with Us{" "}
            </button>
          </a>
        </div>
        <div class="lg:mt-0 lg:col-span-5 lg:flex mt-5">
          <img data-aos="zoom-in-left"
            src="img/crm.png"
            alt="mockup"
          />
        </div>
      </div>
    </section>

    <hr />

    {/* hero section end  */}

    {/* about-it */}

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/crm-2.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            We Provide Best Solution
          </h2>
          <p class="mb-6 font-light text-gray-700 md:text-lg dark:text-gray-400">
        We specialize in providing robust Customer Relationship Management (CRM) solutions and software services to streamline your business processes, enhance customer engagement, and drive growth. Whether you are a small business or a large enterprise, our tailored CRM and software solutions are designed to meet your specific needs and empower your organization with advanced tools and technologies.
          </p>
          <Link className="px-6" to="/Contact">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </Link>
        </div>
      </div>
    </section>

    <section class="bg-white dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            Why Choose Us?
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Expertise: </strong> Our team consists of skilled CRM specialists and software developers with extensive experience in delivering successful projects for diverse industries.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Tailored Solutions:</strong> We understand that every business is unique, and our solutions are tailor-made to address your specific needs and challenges.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Technology Excellence:</strong> We stay up-to-date with the latest technologies and best practices to deliver cutting-edge solutions that give you a competitive edge.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Timely Delivery:</strong>  We adhere to strict timelines and ensure that our services are delivered on schedule without compromising on quality.
          </p>
        </div>

        <img data-aos="fade-left"
          class="w-full dark:hidden"
          src="img/crm-3.png"
          alt="dashboard image"
        />
      </div>
    </section>

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/crm-4.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
          Our CRM and Software Services Offerings
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>CRM Implementation and Customization:</strong>
We help you choose the right CRM platform that aligns with your business goals. Our team ensures a seamless CRM implementation and customizes it to match your unique requirements, from lead management and sales automation to customer support and marketing automation.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> CRM Training and Support: </strong>
Empower your team with comprehensive CRM training to maximize the benefits of the system. Our ongoing support ensures smooth operations, quick issue resolution, and continuous improvements for your CRM setup.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Business Process Automation: </strong>
Streamline your workflows and increase operational efficiency with business process automation. Our experts identify opportunities for automation and develop solutions that save time and reduce manual efforts.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Cloud-Based Solutions:</strong>
Embrace the power of the cloud with our cloud-based software solutions. Enjoy the benefits of scalability, accessibility, and cost-efficiency while ensuring data security and reliability
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Software Testing and Quality Assurance:</strong> 
We conduct rigorous testing and quality assurance to ensure that your software is bug-free, reliable, and meets your business requirements before deployment.
          </p>
        </div>
      </div>
    </section>

    {/* about-it end */}

    {/* free consultations */}

    <section class="bg-gradient-to-r from-indigo-500 to-blue-500 dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
            Request a Free Consultation
          </h2>
          <p class="mb-6 text-gray-100 font-bold md:text-lg">
            Are you ready to elevate your business with expert IT
            consultation? Get in touch with our team today to schedule a free
            consultation. Let's collaborate and make your technology work for
            you.
          </p>
          <Link
            to="/Contact"
            class="text-white bg-orange-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            Contact Now
          </Link>
        </div>
      </div>
    </section>

    {/* end consultation */}
  </div>
  )
}
