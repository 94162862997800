import React from 'react'
import { Link } from 'react-router-dom'


export default function GameDevelopment() {
  return (
    <div>
    {/* hero section */}

    <section class="bg-gradient-to-br border-b-4 border-orange-500 from-red-100 via-green-200 to-indigo-300 dark:bg-gray-900  py-14">
      <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
          <h1 class="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white">
            Welcome to xSafar <br />{" "}
            <span className="text-orange-500"> Game Development Services</span>
          </h1>
          <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
          Level up your gaming ambitions with our top-tier game development services and bring your ideas to life.
          </p>
          <a className="px-6" href="tel:7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </a>

          <a href="https://wa.me/7449703046">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500">
              {" "}
              Chat with Us{" "}
            </button>
          </a>
        </div>
        <div class="lg:mt-0 lg:col-span-5 lg:flex mt-5">
          <img data-aos="zoom-in-left"
            src="img/game-dev.png"
            alt="mockup"
          />
        </div>
      </div>
    </section>

    <hr />

    {/* hero section end  */}

    {/* about-it */}

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/game-dev-1.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            We Provide Best Solution
          </h2>
          <p class="mb-6 font-light text-gray-700 md:text-lg dark:text-gray-400">
          At xSafar Technology, we are passionate about creating immersive and captivating gaming experiences. Our team of talented game developers, designers, artists, and storytellers is committed to bringing your gaming ideas to life. Whether you're an indie developer or a game studio, we offer a wide range of game development services to cater to your unique needs and deliver top-notch gaming products.
          </p>
          <Link className="px-6" to="/Contact">
            <button class="px-5 py-3 rounded-full font-medium text-slate-100 shadow-xl  hover:bg-white duration-150 hover:text-black  bg-orange-500  ">
              {" "}
              Contact Us{" "}
            </button>
          </Link>
        </div>
      </div>
    </section>

    <section class="bg-white dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
            Why Choose Us?
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Game Development Expertise:</strong> Our team comprises passionate game developers with years of experience in the gaming industry.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Creativity and Innovation: </strong>We pride ourselves on our ability to bring innovative and creative ideas to the table, delivering unique gaming experiences.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Timely Delivery: </strong>We understand the importance of meeting deadlines and work diligently to deliver your game on time, without compromising on quality.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Client-Centric Approach:</strong> We value your input and collaborate closely with you throughout the development process to ensure your vision is brought to life.
          </p>
        </div>

        <img data-aos="fade-left"
          class="w-full dark:hidden"
          src="img/game-dev-2.png"
          alt="dashboard image"
        />
      </div>
    </section>

    <section class="bg-gray-200 dark:bg-gray-900">
      <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6 flex flex-col-reverse">
        <img data-aos="fade-right"
          class="w-full dark:hidden"
          src="img/game-dev-3.png"
          alt="dashboard image"
        />

        <div class="mt-4 md:mt-0">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 dark:text-white">
          Our Game Development Services
          </h2>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Concept Development: </strong>
We start by understanding your game idea and vision. Our team collaborates with you to flesh out the concept, define gameplay mechanics, and create a solid foundation for your game's development.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>2D and 3D Game Development:</strong>
From 2D platformers to 3D open-world adventures, we have expertise in developing games for various genres and platforms. Our developers utilize cutting-edge technologies and industry-best practices to ensure your game runs smoothly and looks stunning.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong> Mobile Game Development:</strong>
Leverage the booming mobile gaming market with our mobile game development services. We design and develop captivating games optimized for iOS and Android devices, ensuring a seamless user experience on mobile platforms.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Console and PC Game Development:</strong>
We have experience creating games for consoles and PCs, providing high-quality gaming experiences that cater to the preferences of dedicated gamers and enthusiasts.
          </p>
          <p class="mb-6 text-gray-700 md:text-m font-semibold dark:text-gray-400">
            <strong>Game Monetization and Publishing:</strong>
We can assist you in developing effective monetization strategies and help you navigate the game publishing process. Our expertise extends to in-app purchases, ads integration, and other revenue-generating opportunities.
          </p>
        </div>
      </div>
    </section>

    {/* about-it end */}

    {/* free consultations */}

    <section class="bg-gradient-to-r from-indigo-500 to-blue-500 dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
            Request a Free Consultation
          </h2>
          <p class="mb-6 text-gray-100 font-bold md:text-lg">
            Are you ready to elevate your business with expert IT
            consultation? Get in touch with our team today to schedule a free
            consultation. Let's collaborate and make your technology work for
            you.
          </p>
          <Link
            to="/Contact"
            class="text-white bg-orange-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            Contact Now
          </Link>
        </div>
      </div>
    </section>

    {/* end consultation */}
  </div>
  )
}
